<template>
    <div class="p-info-content">
        <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
            <div class="section-title margin-deliver">{{ $t("Employment History") }}</div>
            <router-link class="button danger-button" :to="{ name: 'candidate.employments'}"><span>{{ $t("Cancel") }}</span></router-link>
        </div>
        <div class="details__card">
            <form>
                <div class="details__card__head">
                    <h4>{{ $t("Company") }}</h4>
                </div>
                <div class="details__card__body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t("Company Name") }}*</label>
                                <vue-autosuggest
                                        ref="autosuggest"
                                        v-model="form.company_name"
                                        @click="companyNameClickHandler"
                                        @selected="companyNameSelectHandler"
                                        @input="companyNameInputHandler"
                                        :suggestions="filteredCompanySuggestions"
                                        :inputProps="{id: 'company_name', placeholder: $t('Enter company name'), class: 'form-control' }">
                                    <template slot-scope="{ suggestion }">
                                        <b>{{ suggestion.item }}</b>
                                    </template>
                                </vue-autosuggest>
                                <error-message :message="errors.company_name"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t("Designation") }}*</label>
                                <vue-autosuggest
                                        ref="autosuggest"
                                        v-model="form.designation"
                                        @click="designationClickHandler"
                                        @selected="designationNameSelectHandler"
                                        @input="designationInputHandler"
                                        :suggestions="filteredDesignationSuggestions"
                                        :inputProps="{id: 'designation', placeholder: $t('Enter designation'), class: 'form-control' }">
                                    <template slot-scope="{ suggestion }">
                                        <div>
                                            <b>{{ suggestion.item }}</b>
                                        </div>
                                    </template>
                                </vue-autosuggest>
                                <error-message :message="errors.designation"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ $t("Department") }}*</label>
                                <vue-autosuggest
                                        ref="autosuggest"
                                        v-model="form.department"
                                        @click="departmentClickHandler"
                                        @selected="departmentSelectHandler"
                                        @input="departmentInputHandler"
                                        :suggestions="filteredDepartmentSuggestions"
                                        :inputProps="{id: 'department', placeholder: $t('Enter department'), class: 'form-control' }">
                                    <template slot-scope="{ suggestion }">
                                        <div>
                                            <b>{{ suggestion.item }}</b>
                                        </div>
                                    </template>
                                </vue-autosuggest>
                                <error-message :message="errors.department"/>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="label-wrap d-flex justify-content-between">
                                    <label>{{ $t("Employment Period") }}*</label>
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="form.is_currently_working" @change="emptyToDate"/>
                                        <span>{{ $t('Currently Working') }}</span>
                                    </label>
                                </div>
                                <div class="date-group">
                                    <div class="date-field-wrap">
                                        <div class="date-picker">
                                            <date-picker
                                                    v-model="form.from_date"
                                                    :placeholder="$t('mm/dd/yyyy')"
                                                    autocomplete="off"
                                                    :config="datePickerOptions"
                                            ></date-picker>
                                        </div>

                                        <error-message :message="errors.from_date"/>
                                    </div>
                                    <h4 class="text-capitalize">{{ $t("to") }}</h4>
                                    <div class="date-field-wrap">
                                        <div class="date-picker">
                                            <date-picker
                                                    :disabled="form.is_currently_working"
                                                    v-model="form.to_date"
                                                    :placeholder="$t('mm/dd/yyyy')"
                                                    autocomplete="off"
                                                    :config="datePickerOptions"
                                            ></date-picker>
                                        </div>
                                        <error-message :message="errors.to_date"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-4">
                        <div class="label-wrap">
                            <label>{{ $t("Responsibilities") }}</label>
                        </div>
                        <textarea v-model="form.responsibilities" :placeholder="$t('Write Job Responsibilities')" cols="30" rows="10" class="form-control"></textarea>
                        <error-message :message="errors.responsibilities"/>
                    </div>
                </div>
                <div class="details__card__footer">
                    <submit-button :click="submitCandidateEmployment" :loading="isLoading" type="primary-button ml-auto">{{ $t("Save") }}</submit-button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {
    getCandidateCompany,
    getCandidateDepartments,
    getCandidateDesignation,
    getCandidateEmployment
} from "../../../../app/api/CommonRequest";
import Multiselect from "vue-multiselect";
import {VueAutosuggest} from 'vue-autosuggest';
import ErrorMessage from "../../../../components/common/ErrorMessage";
import client from "../../../../app/api/Client";
import {datePickerIcons} from "../../../../config/options";
import moment from "moment";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import {debounce} from "lodash";

export default {
    name: "Form",
    components: {
        Multiselect,
        VueAutosuggest,
        ErrorMessage,
        datePicker
    },
    data() {
        return {
            isLoading: false,
            showForm: false,
            form: {
                id: null,
                company_name: '',
                designation: '',
                company: {},
                department: '',
                from_date: null,
                to_date: null,
                is_currently_working: null,
                responsibilities: null,
            },
            departments: [],
            companies: [],
            designations: [],
            employments: [],
            hasEmployment: true,
            errors: {},
            selected: null,
            selectedCompany: '',
        }
    },
    computed: {
        filteredCompanySuggestions() {
            if (this.companies.length > 0) {
                return [{
                    data: this.companies
                }];
            }
            return [];
        },
        filteredDesignationSuggestions() {
            if (this.designations.length > 0) {
                return [{data: this.designations}];
            }
            return [];
        },
        filteredDepartmentSuggestions() {
            if (this.departments.length > 0) {
                return [{data: this.departments}];
            }
            return [];
        },
        now() {
            return moment().format();
        },
        datePickerOptions() {
            return {
                format: 'MM/DD/YYYY',
                maxDate: moment().subtract(1, "day").format('MM/DD/YYYY'),
                icons: datePickerIcons,
                useCurrent: true
            }
        }
    },
    methods: {
        companyNameInputHandler: debounce(function (item) {
            getCandidateCompany(item).then((data) => {
                this.companies = data.map(company => company.name);
            });
        }, 1000),

        designationInputHandler: debounce(function (item) {
            getCandidateDesignation(item).then((data) => {
                this.designations = data;
            });
        }, 1000),

        departmentInputHandler: debounce(function (item) {
            getCandidateDepartments(item).then((data) => {
                this.departments = data.map(department => department.name);
            });
        }, 1000),

        companyNameSelectHandler(item) {
            if (item) {
                this.form.company_name = item.item;
            }
        },

        designationNameSelectHandler(item) {
            if (item) {
                this.form.designation = item.item;
            }
        },

        departmentSelectHandler(item) {
            if (item) {
                this.form.department = item.item;
            }
        },

        async companyNameClickHandler() {
            let companies = await getCandidateCompany();
            this.companies = companies.map(company => company.name);
        },

        async designationClickHandler() {
            this.designations = await getCandidateDesignation();
        },

        async departmentClickHandler() {
            let departments = await getCandidateDepartments();
            this.departments = departments.map(dept => dept.name);
        },

        async getEmployment(id) {
            this.isContentLoading = true;
            try {
                let {data} = await getCandidateEmployment(id);
                this.form = data;
                this.form.from_date = data.from_date ? moment(data.from_date) : null;
                this.form.to_date = data.to_date ? moment(data.to_date) : null;
                if (this.form.department) {
                    this.form.department.name = this.$t(data.department.name);

                }
            } catch (e) {
            }
            this.isContentLoading = false;
        },
        submitCandidateEmployment() {
            this.isLoading = true;
            client()
            .post('/candidate/employment', this.form)
            .then(({data}) => {
                this.$toast.success(data.message);
                this.$router.push({name: 'candidate.employments'});
            })
            .catch(error => {
                return this.showErrorText(error)
            })
            .finally(() => {
                this.isLoading = false;
            });
        },
        showErrorText(error) {
            if (typeof error == 'string') {
                return this.$toast.error(error);
            } else if (error.response !== undefined && error.response.status === 422) {/* validation error */
                if (typeof error.response.data.message == 'string') {
                    return this.$toast.error(error.response.data.message);/* System error */
                }
                this.errors = _helper.serializeValidationMessage(error.response.data.message);
                return this.$toast.error(this.$t('Oops! Please check your input'));
            } else {
            }
        },
        emptyToDate() {
            if (this.form.is_currently_working) {
                this.form.to_date = null;
            }
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.getEmployment(this.$route.params.id);
        }
    }

}
</script>

<style>

#autosuggest__input {
    outline: none;
    position: relative;
    display: block;
    border: 1px solid #616161;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.autosuggest__results-container {
    position: relative;
    width: 100%;
}

.autosuggest__results {
    font-weight: 300;
    margin: 0;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 0px;
    max-height: 320px;
    overflow: auto;
}

.autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}


.autosuggest__results .autosuggest__results-item {
    cursor: pointer;
    padding: 10px 15px;
    border-bottom: 1px solid #e0e0e0;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
    border-top: none;
}

.autosuggest__results .autosuggest__results_title {
    color: gray;
    font-size: 11px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
    background-color: #F6F6F6;
}

</style>
